<template>
  <div class="w-full fixed z-50 top-0 left-0 p-2">
    <nav id="nav" class="w-full bg-black-900 border border-white flex flex-col p-4 rounded-[32px]">
      <div class="flex flex-row justify-between items-center">
        <a :href="url">
          <img :src="logo" class="max-w-[90px] w-full" alt="Hiroy logo" />
        </a>
        <a v-for="(link, i) in links" :key="link + i" :href="link.link.url">{{
          link.link.title
        }}</a>
        <div id="menu" class="hamburger" :class="{ 'is-active': isActive }" @click="showMenu()">
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>
      <div id="subMenu" class="w-full text-right" :class="{ 'sub-open': isActive }">
        <ul class="gap-2 p-6 flex flex-col">
          <li v-for="(link, i) in mega" :key="link + i">
            <a :href="link.link.url">{{ link.link.title }}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: ['links', 'mega', 'url', 'logo'],
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    showMenu: function () {
      this.isActive = !this.isActive
    }
  }
}
</script>
